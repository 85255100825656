import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import logo from '../assets/images/svg/logo-nav.svg'
import Header from '../common/Header'
import { SLIDER_LIST } from '../common/Helper'
import Icons from '../common/Icons'

const SocialTensor = () => {
  const [isClicked, setIsClicked] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [hoveredText, setHoveredText] = useState('TourGame')
  const [show, setShow] = useState(true)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [clickedText, setClickedText] = useState('TourGame')
  const hideTimeoutRef = useRef(null)
  const isInstantHoverOut = useRef(true)
  const [mobileSlides, setMobileSlides] = useState([...SLIDER_LIST, ...SLIDER_LIST, ...SLIDER_LIST])

  const HANDLE_MOUSE_OVER = async (e) => {
    if (screenWidth > 1200) {
      isInstantHoverOut.current = false
      if (hideTimeoutRef.current) {
        await hideTimeoutRef.current
      }
      const hoveredSlide = e.target.closest('.slider_text')
      if (hoveredSlide) {
        hoveredSlide.classList.add('hovered_position')
        setHoveredText(e.target.textContent)
        setIsHovered(true)
        hideTimeoutRef.current = null
      }
    }
  }

  const HANDLE_MOUSE_OUT = (e) => {
    if (screenWidth > 1200) {
      const hoveredSlide = e.target.closest('.slider_text')
      if (hoveredSlide) {
        hoveredSlide.classList.remove('hovered_position')
        setIsHovered(false)
        isInstantHoverOut.current = true
        hideTimeoutRef.current = new Promise((r) =>
          setTimeout(() => {
            r(true)
          }, 300)
        )
      }
    }
  }

  const HANDLE_CLICK = (e) => {
    const clickedSlide = e.target.closest('.slider_text')
    if (clickedSlide) {
      setClickedText(clickedSlide.textContent)
      if (screenWidth < 1200) {
        setIsClicked(!isClicked)
        setShow(false)
      }
    }
  }

  const HANDLE_SHOW = (e) => {
    if (screenWidth < 1200) {
      const centerSlide = e.target.closest('.active_slide')
      if (centerSlide) {
        setShow(false)
      }
    }
  }

  const HANDLE_HIDE = () => {
    setShow(true)
  }

  const HANDLE_DOCUMENT_CLICK = (e) => {
    if (screenWidth > 1200) {
      const clickedInside = e.target.closest('.active_slide') || e.target.closest('.common_box')
      if (!clickedInside) {
        setIsClicked(false)
        setIsHovered(false)
      }
    }
  }

  const fetchMoreMobileData = () => {
    setMobileSlides((current) => [...current, ...SLIDER_LIST])
  }

  useEffect(() => {
    document.addEventListener('click', HANDLE_DOCUMENT_CLICK)
    return () => {
      document.removeEventListener('click', HANDLE_DOCUMENT_CLICK)
    }
  }, [screenWidth])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const clickedItem = SLIDER_LIST.find((item) => item.text === clickedText)

  const hasImages = clickedItem?.images?.cardOne || clickedItem?.images?.cardTwo

  return (
    <div className='position-relative overflow-hidden' style={{ height: '100dvh' }}>
      <div className='d-sm-block d-none branding_padding position-absolute z-3'>
        <a href='/'>
          <img loading='eager' src={logo} alt='logo' />
        </a>
      </div>

      <div
        className='w-100 position-absolute top-0 bottom-0 bg-black d-flex align-items-center justify-content-center overflow-y-scroll scroll_height'
        style={{ height: '100%' }}
      >
        <div className='d-flex flex-column w-100 d-none d-md-block slider_p_hover'>
          {/* PC slider start */}
          <div className='slider_container'>
            <div className='slider_parent'>
              {SLIDER_LIST.map((obj, i) => (
                <span
                  onClick={(e) => {
                    HANDLE_CLICK(e)
                    HANDLE_SHOW(e)
                  }}
                  onMouseEnter={HANDLE_MOUSE_OVER}
                  onMouseLeave={HANDLE_MOUSE_OUT}
                  className='slider_item active_slide mb-0 text_lg opacity_20 text-white text-center text-nowrap slider_text cursor_pointer fst-italic'
                  key={i}
                >
                  {obj.text}
                </span>
              ))}
            </div>
            <div className='slider_parent'>
              {SLIDER_LIST.map((obj, i) => (
                <span
                  onClick={(e) => {
                    HANDLE_CLICK(e)
                    HANDLE_SHOW(e)
                  }}
                  onMouseEnter={HANDLE_MOUSE_OVER}
                  onMouseLeave={HANDLE_MOUSE_OUT}
                  className='slider_item active_slide mb-0 text_lg opacity_20 text-white text-center text-nowrap slider_text cursor_pointer fst-italic'
                  key={i}
                >
                  {obj.text}
                </span>
              ))}
            </div>
          </div>
          <div className='slider_container'>
            <div className='slider_reverse'>
              {[...SLIDER_LIST].reverse().map((obj, i) => (
                <span
                  onClick={(e) => {
                    HANDLE_CLICK(e)
                    HANDLE_SHOW(e)
                  }}
                  onMouseEnter={HANDLE_MOUSE_OVER}
                  onMouseLeave={HANDLE_MOUSE_OUT}
                  className='slider_item active_slide mb-0 text_lg opacity_20 text-white text-center text-nowrap slider_text cursor_pointer fst-italic'
                  key={i}
                >
                  {obj.text}
                </span>
              ))}
            </div>
            <div className='slider_reverse'>
              {[...SLIDER_LIST].reverse().map((obj, i) => (
                <span
                  onClick={(e) => {
                    HANDLE_CLICK(e)
                    HANDLE_SHOW(e)
                  }}
                  onMouseEnter={HANDLE_MOUSE_OVER}
                  onMouseLeave={HANDLE_MOUSE_OUT}
                  className='slider_item active_slide mb-0 text_lg opacity_20 text-white text-center text-nowrap slider_text cursor_pointer fst-italic'
                  key={i}
                >
                  {obj.text}
                </span>
              ))}
            </div>
          </div>
          {/* PC slider end */}

          {/* PC showcase start */}
          <div className='d-none d-xl-block'>
            <div
              className={`${
                hoveredText &&
                SLIDER_LIST.find((item) => item.text === hoveredText)?.images?.cardOne &&
                isHovered &&
                !isInstantHoverOut.current &&
                'hovered_position'
              } common_box bg-black ${
                hoveredText && SLIDER_LIST.find((item) => item.text === hoveredText)?.images?.cardTwo ? '' : 'trx_0'
              } trx_full common_box_position common_box_position_transform_1 d-flex align-items-end justify-content-between`}
            >
              <p className='common_text_transform text-nowrap text-white fw-normal fst-italic text_sm lh_16'>UI UX</p>
              <img
                key={hoveredText}
                loading='eager'
                className='social_sensor_img_w'
                src={
                  hoveredText && SLIDER_LIST.find((item) => item.text === hoveredText)?.images?.cardOne
                    ? SLIDER_LIST.find((item) => item.text === hoveredText)?.images.cardOne
                    : ''
                }
                alt='social_sensor'
              />
            </div>
            <div
              className={`${
                hoveredText &&
                SLIDER_LIST.find((item) => item.text === hoveredText)?.images?.cardTwo &&
                isHovered &&
                !isInstantHoverOut.current &&
                'hovered_position'
              } ${
                hoveredText && SLIDER_LIST.find((item) => item.text === hoveredText)?.images?.cardOne
                  ? ''
                  : 'expand_single_card'
              }  common_box bg-black common_box_position social_tensor_transition_2`}
            >
              <div className='position-relative d-flex justify-content-end'>
                <p className='common_text_transform branding_text_pos mb-4 text-white fw-normal fst-italic text_sm lh_16'>
                  Branding
                </p>
                <img
                  key={hoveredText}
                  loading='eager'
                  className='social_sensor_img_w'
                  src={
                    hoveredText && SLIDER_LIST.find((item) => item.text === hoveredText)?.images?.cardTwo
                      ? SLIDER_LIST.find((item) => item.text === hoveredText)?.images.cardTwo
                      : ''
                  }
                  alt='social_tensor'
                />
              </div>
            </div>
          </div>
          {/* PC showcase end */}
        </div>

        {/* Mobile slider start */}
        <div
          id='mobile-slider-scroller'
          className='d-block d-md-none position-absolute top-0 pt_24 pb_65'
          style={{
            height: '100vh',
            overflowY: 'scroll',
          }}
        >
          <InfiniteScroll
            hasMore
            next={fetchMoreMobileData}
            scrollableTarget='mobile-slider-scroller'
            dataLength={mobileSlides.length}
          >
            <div className='d-flex d-md-none flex-column gap-4 align-items-center justify-content-center'>
              {mobileSlides.map((obj, i) => (
                <span
                  onClick={(e) => {
                    HANDLE_CLICK(e)
                    HANDLE_SHOW(e)
                  }}
                  onMouseEnter={HANDLE_MOUSE_OVER}
                  onMouseLeave={HANDLE_MOUSE_OUT}
                  className='slider_item active_slide mb-0 text_lg opacity_20 text-white text-center text-nowrap slider_text cursor_pointer fst-italic'
                  key={i}
                >
                  {obj.text}
                </span>
              ))}
            </div>
          </InfiniteScroll>
        </div>
        {/* Mobile slider end */}
      </div>

      {/* Mobile showcase start */}
      <div className='d-flex d-xl-none justify-content-center z-3'>
        {!show && hasImages && (
          <div
            onClick={() => setShow(!show)}
            className='position-absolute top-0 start-0 bg-black z-3 opacity-75 h-100 w-100'
          ></div>
        )}
        {hasImages && (
          <div className={`${show ? 'card_hidden' : 'card_visible'} responsive_box_pos z-3`}>
            <div className='d-flex flex-column align-items-end card_mobile_spacing'>
              <div onClick={HANDLE_HIDE} className='d-flex justify-content-center cross_icon_box align-items-center'>
                <Icons icon='Cross_Icon' className='cursor_pointer' />
              </div>
              <div className='responsive_box grey d-flex flex-column gap_20'>
                {clickedItem?.images?.cardOne && (
                  <div className='position-relative justify-content-end d-flex gap_12 w-100 ratio-box'>
                    <p className='ui_ux_text_transform mb-0 text-nowrap text-white fw-normal fst-italic text_sm lh_16'>
                      UI UX
                    </p>
                    <img
                      loading='eager'
                      className='social_sensor_img_w'
                      src={clickedItem.images.cardOne}
                      alt='sensor'
                    />
                  </div>
                )}
                {clickedItem?.images?.cardTwo && (
                  <div className='position-relative justify-content-end d-flex gap_12 w-100 ratio-box'>
                    <p className='branding_text_transform text-nowrap mb-0 text-white fw-normal fst-italic text_sm lh_16'>
                      Branding
                    </p>
                    <img
                      loading='eager'
                      className='social_sensor_img_w sensor_second_img'
                      src={clickedItem.images.cardTwo}
                      alt='tensor'
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Mobile showcase end */}

      <div className='position-absolute bottom-0 z-3 flex-column w-100 justify-content-center'>
        <Header />
      </div>
    </div>
  )
}

export default SocialTensor
