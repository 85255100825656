import React, { useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'
import Header from '../common/Header'
import bedrockVideo from '../assets/video/bedrock.mp4'
import poster from '../assets/images/video_poster.png'

function BedRocks() {
  const videoRef = useRef(null)

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.log('Auto-play was prevented')
          // 可以在这里添加fallback行为
        })
      }
    }
    playVideo()

    // 响应用户交互后尝试播放
    const handleUserInteraction = () => {
      playVideo()
      document.removeEventListener('click', handleUserInteraction)
    }
    document.addEventListener('click', handleUserInteraction)

    return () => {
      document.removeEventListener('click', handleUserInteraction)
    }
  }, [])
  return (
    <div className='bg-black d-flex flex-column vh_100'>
      <Container className='d-flex flex-column justify-content-center common_padding align-items-center flex-grow-1'>
        <video
          autoPlay
          loop
          muted
          playsInline
          className='w-100 video_width'
          ref={videoRef}
          poster={poster}
          controls={false}
        >
          <source src={bedrockVideo} type='video/mp4' />
        </video>
      </Container>
      <div className='header_custom_spacing position-absolute bottom-0 z-3 flex-column w-100 justify-content-center'>
        <Header />
      </div>
    </div>
  )
}

export default BedRocks
