import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import BedRockIcons from './components/BedRockIcons'
import Future from './components/Future'
import BedRocks from './components/BedRocks'
import SocialTensor from './components/SocialTensor'
import ResourcePreloader from './common/ResourcePreloader'

import social_sensor from './assets/images/webp/social-sensor.webp'
import social_tensor from './assets/images/webp/social-tensor.webp'
import niche_branding from './assets/images/png/niche-tensor-branding.png'
import niche_ui from './assets/images/webp/niche-tensor-ui.webp'
import aperture_branding from './assets/images/png/aperture-branding.png'
import aperture_ui from './assets/images/webp/aperture-ui.webp'
import infini_branding from './assets/images/png/infini-branding.png'
import tour_game_ui from './assets/images/webp/tour-game-ui.webp'
import tour_branding from './assets/images/png/tour-branding.png'
import cyber_branding from './assets/images/png/cyber-connect-branding.png'
import cyber_ui from './assets/images/webp/cyber-connect-ui.webp'
import link_branding from './assets/images/png/link-branding.png'
import link_ui from './assets/images/webp/link-ui.webp'
import tensor_market_brand from './assets/images/png/tensor-market-ui.png'
import base_brand from './assets/images/png/base-perp-brand.png'
import base_ui from './assets/images/png/base-perp-ui.png'
import syntheia_ui from './assets/images/png/syntheia-ui.png'
import syntheia_brand from './assets/images/png/syntheia-brand.png'
import lcash_ui from './assets/images/png/lcash-ui.png'
import lcash_brand from './assets/images/png/lcash-brand.png'
import tabi_brand from './assets/images/png/tabi-brand.png'
import tensure_land_brand from './assets/images/png/tensure-land-brand.png'
import nft_brand from './assets/images/png/nft-brand.png'
import nft_ui from './assets/images/png/nft-ui.png'
import ontology_brand from './assets/images/png/ontology-brand.png'
import ontology_ui from './assets/images/png/ontology-ui.png'
import open_ocean_brand from './assets/images/png/open-ocean-brand.png'
import open_ocean_ui from './assets/images/png/open-ocean-ui.png'
import tao_brand from './assets/images/png/tao-brand.jpg'
import tao_ui from './assets/images/png/tao-ui.jpg'

const resourcesToPreload = [
  social_sensor,
  social_tensor,
  niche_branding,
  niche_ui,
  aperture_branding,
  aperture_ui,
  infini_branding,
  tour_game_ui,
  tour_branding,
  cyber_branding,
  cyber_ui,
  link_branding,
  link_ui,
  tensor_market_brand,
  base_brand,
  base_ui,
  syntheia_ui,
  syntheia_brand,
  lcash_ui,
  lcash_brand,
  tabi_brand,
  tensure_land_brand,
  nft_brand,
  nft_ui,
  ontology_brand,
  ontology_ui,
  open_ocean_brand,
  open_ocean_ui,
  tao_brand,
  tao_ui,
]
function App() {
  return (
    <div className='App'>
      <ResourcePreloader resources={resourcesToPreload} />
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<BedRocks />} />
          <Route exact path='/about' element={<Future />} />
          <Route exact path='/cases' element={<SocialTensor />} />
          {/* <Route exact path='https://t.me/akamft' element={<BedRockIcons />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
