import { useEffect } from 'react'

const ResourcePreloader = ({ resources }) => {
  useEffect(() => {
    const preloadResource = (resource) => {
      return new Promise((resolve, reject) => {
        let el

        el = new Image()
        el.src = resource

        el.onload = resolve
        el.onerror = reject
      })
    }

    const preloadAll = async () => {
      try {
        await Promise.all(resources.map(preloadResource))
        console.log('All resources preloaded successfully')
      } catch (error) {
        console.error('Error preloading resources:', error)
      }
    }

    preloadAll()
  }, [resources])

  return null // This component doesn't render anything
}

export default ResourcePreloader
